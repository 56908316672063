import axios from "axios";
import config from "./config.json"
import {getWithExpiry} from "./functions"

const home = (email = "null") => {
    return axios.get(config.api_gw_endpoint + '/home?email=' + email, {
        headers: getRequestHeaders()
    })
}

const getAppStreamStatus = () => {
    return axios.get(config.api_gw_endpoint + '/fleet-status', {
        headers: getRequestHeaders()
    })
}

const getStreamingURL = () => {
    return axios.post(config.api_gw_endpoint + '/get-streaming-url', {}, {
        headers: getRequestHeaders()
    })
}

const getRequestHeaders = () => {
    return {
        "Authorization": "Bearer " + getWithExpiry('access_token'),
        "Content-Type": "application/json"
    }
}

export {
    getAppStreamStatus,
    home,
    getStreamingURL
}