import React, {useEffect, useState} from "react";
import {Alert, AppBar, Avatar, Card, CardActions, CardContent, Chip, CircularProgress, Container, Grid, IconButton, Link, Snackbar, Toolbar, Tooltip, Typography} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import {getUserInfo, logout} from "./functions"
import logo from "./logo.png";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from "@mui/material/Button";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CachedIcon from '@mui/icons-material/Cached';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {useNavigate} from "react-router-dom";
import {getAppStreamStatus, getStreamingURL, home} from "./api"
import config from "./config.json"

const getDuration = (nMinutes) => {
    let hours = (nMinutes / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

export default function Home() {
    let navigate = useNavigate();
    const snackBarPosition = {vertical: 'bottom', horizontal: 'center'}
    const [snackBarVisible, setSnackBarVisible] = useState(false)
    const [userEmail, setUserEmail] = useState("")
    const [appStreamStatus, setAppStreamStatus] = useState(null)
    const [streamingURL, setStreamingURL] = useState(null)
    const [streamingURLExpiry, setStreamingURLExpiry] = useState(null);
    const [fleetRunning, setFleetRunning] = useState(false);
    const [expiresIn, setExpiresIn] = useState("");
    const [isUserPending, setIsUserPending] = useState(true);
    const [isUserAdmin, setIsUserAdmin] = useState(false)

    useEffect(() => {
        getUserInfo().then(r => {
            setUserEmail(r.data.email)
            setSnackBarVisible(true)
            home(r.data.email).then(r => {
                const data = r.data
                if (data.hasOwnProperty('fleet_name')) {
                    setIsUserPending(false)
                }

                if (data.hasOwnProperty('streaming_url')) {
                    setStreamingURL(data.streaming_url)
                }

                if (data.hasOwnProperty('expiry_date')) {
                    setStreamingURLExpiry(data.expiry_date)
                    setExpiresIn(getDuration(Math.floor((data.expiry_date - (Date.now() / 1000)) / 60)))
                }

                if (data.hasOwnProperty('role')) {
                    setIsUserAdmin(data.role === "admin")
                }
            }).catch(e => {
                console.error(e)
            })
        }).catch(e => {
            if (e.response.status === 401) {
                window.location.href = "/"
            }
        })


    }, [])


    useEffect(() => {
        if (!isUserPending) {
            getAppStreamStatus().then(r => {
                setAppStreamStatus(r.data)
                setFleetRunning(r.data.fleet_status === "RUNNING")
            }).catch(e => {
                if (e.response.status === 401) {
                    window.location.href = "/"
                }
            })
        }
    }, [isUserPending])

    return (
        <Container disableGutters maxWidth="md" component="main" sx={{pt: 2, pb: 2}}>
            <AppBar position='static' sx={{bgcolor: 'white'}} color='default' elevation={1}>
                <Toolbar sx={{flexWrap: 'wrap'}}>
                    <Box component="img" src={logo} sx={{height: 25, mr: 1}}/>
                    <Typography variant="overline" color="inherit" noWrap sx={{flexGrow: 1, mt: 1}}>
                        Customer Portal
                    </Typography>
                    <Tooltip title={'Logged in as ' + userEmail}>
                        <Avatar sx={{width: 26, height: 26, bgcolor: '#82E0AA'}}>{userEmail.charAt(0).toUpperCase()}</Avatar>
                    </Tooltip>

                    {
                        isUserAdmin ? <Tooltip title="Admin">
                            <IconButton sx={{marginLeft: 1.3}} onClick={() => {
                                navigate('/admin', {replace: true})
                            }}>
                                <AdminPanelSettingsIcon sx={{color: '#D35400'}}/>
                            </IconButton>
                        </Tooltip> : ""
                    }

                    <Tooltip title="Logout">
                        <IconButton sx={{marginLeft: 1}} onClick={() => {
                            logout()
                            window.location.href = config.logout_endpoint
                            // navigate('/')
                        }}>
                            <LogoutIcon sx={{color: '#D35400'}}/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>

            <Grid container spacing={2} sx={{mt: 2}}>

                <Grid item xs={12} style={{textAlign: "center"}}>
                    <Card variant="outlined">
                        {appStreamStatus == null ? <CircularProgress sx={{mt: 3, mb: 3}}/> :
                            <div>
                                <CardContent>
                                    <Typography variant="overline" component="div">
                                        Stack: <strong>{appStreamStatus.stack_name}</strong> | Fleet: <strong>{appStreamStatus.fleet_name}</strong>
                                    </Typography>

                                    <Box sx={{mt: 0.5}}>
                                        {appStreamStatus.fleet_status === 'STARTING' ? <Chip icon={<HourglassEmptyIcon/>} label="Console Starting" color="info"/> : ""}
                                        {appStreamStatus.fleet_status === 'RUNNING' ? <Chip icon={<DoneIcon/>} label="Console Available" color="success"/> : ""}
                                        {appStreamStatus.fleet_status === 'STOPPING' ? <Chip icon={<HourglassEmptyIcon/>} label="Console Stopping" color="warning"/> : ""}
                                        {appStreamStatus.fleet_status === 'STOPPED' ? <Chip icon={<ClearIcon/>} label="Console is not available at this time" color="error"/> : ""}
                                    </Box>

                                    {appStreamStatus.fleet_status === 'RUNNING' ? <Box sx={{mt: 1.5}}>
                                        <Button disabled={streamingURL !== null} onClick={() => {
                                            getStreamingURL().then(r => {
                                                setStreamingURL(r.data.url)
                                                setStreamingURLExpiry(r.data.expiry_date)
                                                setExpiresIn(getDuration(Math.floor((r.data.expiry_date - (Date.now() / 1000)) / 60)))
                                            }).catch(e => {
                                                console.error(e)
                                            })
                                        }} variant="outlined" color="success">Get Streaming URL</Button>
                                    </Box> : ""}

                                    {
                                        streamingURL === null || !fleetRunning ? "" :
                                            <Box sx={{mt: 1}}>
                                                <Link href={'amazonappstream:' + Buffer.from(streamingURL).toString('base64')} target="_blank" color="inherit">
                                                    Open AppStream <OpenInNewIcon sx={{fontSize: 14}}/>
                                                </Link>
                                                <Box>
                                                    <Typography variant="caption">
                                                        Make sure you have the AppStream 2.0 Windows Client installed. This session expires in {expiresIn}
                                                    </Typography>
                                                </Box>
                                                <Link href={streamingURL} target="_blank" color="inherit">
                                                    Open in Browser <OpenInNewIcon sx={{fontSize: 14}}/>
                                                </Link>
                                            </Box>
                                    }

                                </CardContent>
                                {streamingURL === null || !fleetRunning ? "" :
                                    <CardActions>
                                        <Button sx={{marginLeft: "auto"}} startIcon={<CachedIcon/>} size="small" onClick={() => {
                                            getAppStreamStatus().then(r => {
                                                setAppStreamStatus(r.data)
                                                setFleetRunning(r.data.fleet_status === "RUNNING")
                                                setExpiresIn(getDuration(Math.floor((streamingURLExpiry - (Date.now() / 1000)) / 60)))
                                            })
                                        }}>
                                            Refresh
                                        </Button>
                                    </CardActions>
                                }
                            </div>
                        }

                        {
                            isUserPending ? <Typography sx={{color: '#A04000', mb: 2}} variant="subtitle2">User pending activation, please contact SoftSolutions for further support.</Typography> : ""
                        }
                    </Card>
                </Grid>

                <Grid item xs={12} style={{textAlign: "center", color: "#808B96"}}>
                    <Typography variant="caption">
                        © {(new Date()).getFullYear()} SoftSolutions! S.r.l.
                    </Typography>
                </Grid>

            </Grid>

            <Snackbar anchorOrigin={snackBarPosition} transitionDuration={500} open={snackBarVisible} autoHideDuration={6000} onClose={() => setSnackBarVisible(false)}>
                <Alert onClose={() => setSnackBarVisible(false)} severity="success" sx={{width: '100%'}}>
                    Logged in as {userEmail}
                </Alert>
            </Snackbar>
        </Container>
    )
}


