import axios from "axios";
import config from "./config.json"

const getTokenResponse = code => {
    return axios.post(config.token_endpoint, new URLSearchParams({
        grant_type: "authorization_code",
        client_id: config.client_id,
        code: code,
        redirect_uri: config.redirect_uri
    }), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
}

const saveTokenResponseToLocalStorage = data => {
    setWithExpiry('access_token', data.access_token, 8)
    setWithExpiry('id_token', data.id_token, 8)
}

const setWithExpiry = (key, value, ttl) => {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + ttl * 3.6e+6,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = key => {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() >= item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

const isLoggedIn = () => getWithExpiry('access_token') !== null

const getUserInfo = () => {
    return axios.get(config.userinfo_endpoint, {
        headers: {
            "Authorization": "Bearer " + getWithExpiry('access_token')
        }
    })
}


const logout = () => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
}


export {
    getTokenResponse,
    isLoggedIn,
    saveTokenResponseToLocalStorage,
    getUserInfo,
    logout,
    getWithExpiry
}


// let obj = {
//     version: '2.0',
//     routeKey: 'GET /home',
//     rawPath: '/home',
//     rawQueryString: 'email=amod.sachintha@softsolutions.it',
//     headers: {
//         accept: 'application/json, text/plain, */*',
//         'accept-encoding': 'gzip, deflate, br',
//         'accept-language': 'en-US,en;q=0.9',
//         authorization: 'Bearer eyJraWQiOiIxNHRaRmsxWkJJcGJ4bUFhVTRSdDViRFlHR2kxV3JRZTltVlcwVHFGS1JzPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyMzA4YTNjYi03ZGRmLTQwOTktYWIwNi05ZTg5MTBkZGEyODMiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9jeVpUNTVqT1UiLCJ2ZXJzaW9uIjoyLCJjbGllbnRfaWQiOiIzam4xNGZybnBwdHE5c2M2dG5uNjVrcGpnbSIsIm9yaWdpbl9qdGkiOiIwZTA2ZDE2ZS01NWM0LTQwZjAtODUzNS05YWVjYzI0ODY1ZDUiLCJldmVudF9pZCI6ImRmMjY4OGI2LTc1NGEtNDYyYy1iNTE2LTk1ZGE5MmFhYjI5YyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicGhvbmUgb3BlbmlkIGVtYWlsIiwiYXV0aF90aW1lIjoxNjM4ODk1NTUyLCJleHAiOjE2Mzg5ODE5NTIsImlhdCI6MTYzODg5NTU1MiwianRpIjoiYzI5ZTEwMGItNzgxZC00NTI3LTkyMTYtOWY3MTM0Y2JjMTQxIiwidXNlcm5hbWUiOiIyMzA4YTNjYi03ZGRmLTQwOTktYWIwNi05ZTg5MTBkZGEyODMifQ.R0VO2Z2Tkxo7FuYK9UhDLVUyRzCEa5Hq61zvxwB7Aulb7XB0lQD3AgzKa3DgLPB4ZfRLO6NdKDHJz3Gv2JGQncFuTazpHYvhR4jebaBcUOrRYnDEIZShwWhbHWJuIzxZkL4scmrPfv8q7inSTqU1DHm_GkJSRk6p5bq5NFQ4P5BUidNH62pwukRD0r74j5G23k3uUyIUkyDRITXMFeiNlLU8A6mEb7N7RZzso0ZsG3Mfa7pgz0Hp7KF58WKxcK57LgG8OYpUzD5IecW782SmjgahC6nDuM0ondX76bjddIPw1DdOEygqFuz7gSiD6D2cGjAdm4vSfgi5BW8ua9CxgQ',
//         'content-length': '0',
//         host: 'kzopvimyr2.execute-api.eu-west-1.amazonaws.com',
//         origin: 'http://localhost:3000',
//         referer: 'http://localhost:3000/',
//         'sec-ch-ua': '" Not A;Brand";v="99", "Chromium";v="96", "Google Chrome";v="96"',
//         'sec-ch-ua-mobile': '?0',
//         'sec-ch-ua-platform': '"Linux"',
//         'sec-fetch-dest': 'empty',
//         'sec-fetch-mode': 'cors',
//         'sec-fetch-site': 'cross-site',
//         'user-agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36',
//         'x-amzn-trace-id': 'Root=1-61afb03a-0cd8e49f21f805cf761bee3f',
//         'x-forwarded-for': '212.104.224.120',
//         'x-forwarded-port': '443',
//         'x-forwarded-proto': 'https'
//     },
//     queryStringParameters: {email: 'amod.sachintha@softsolutions.it'},
//     requestContext: {
//         accountId: '435247105736',
//         apiId: 'kzopvimyr2',
//         authorizer: {jwt: [Object]},
//         domainName: 'kzopvimyr2.execute-api.eu-west-1.amazonaws.com',
//         domainPrefix: 'kzopvimyr2',
//         http: {
//             method: 'GET',
//             path: '/home',
//             protocol: 'HTTP/1.1',
//             sourceIp: '212.104.224.120',
//             userAgent: 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.45 Safari/537.36'
//         },
//         requestId: 'J_h5NhwmDoEEP6w=',
//         routeKey: 'GET /home',
//         stage: '$default',
//         time: '07/Dec/2021:19:04:26 +0000',
//         timeEpoch: 1638903866751
//     },
//     isBase64Encoded: false
// }