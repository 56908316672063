import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {getTokenResponse, saveTokenResponseToLocalStorage,} from "./functions";
import {useNavigate} from "react-router-dom";

export default function HandleCognitoCallback(props) {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const {setLoggedIn} = props;
    useEffect(() => {
        getTokenResponse(searchParams.get('code')).then(r => {
            saveTokenResponseToLocalStorage(r.data)
            setLoggedIn(true)
            navigate('/home')
        }).catch(e => {
            console.log(e)
        })
    },[searchParams, setLoggedIn, navigate])

    return (
        <code>
            {searchParams.get('code') ?? ''}
        </code>
    )
}